<template>
  <div>
    <el-row>
      <div class="showimg">
        <pic-zoom :url="imgurl" :scale="3"></pic-zoom>
       
      </div>
      <div class="wraper">
         <button @click="left" :disabled="imgIndex==0" :class="imgIndex==0?'active':''">
            <i class="el-icon-arrow-left"></i>
          </button>
         
                <ul id="imgBox">
                  <!-- <li @click="left" v-show='imgIndex!=0'> -->
                  <li
                    v-for="(item, index) in imgList"
                    :key="index"
                    @click="changeImage(index)"
                    :class="index==imgIndex?'imgActive':''"
                  >
                    <img :src="item" alt="图片"/>
                  </li>
                </ul>
         <button @click="right" :disabled="imgIndex>=imgList.length-1"  :class="imgIndex>=imgList.length-1?'active':''">
          <!-- <li @click="right" v-show='imgIndex<imgList.length-1'> -->
            <em class="el-icon-arrow-right"></em>
          </button>
      </div>
      <div class="FavBox"  @click="FavoriteFun()" >
        <img class="FavIcon" :src="FavoriteFlag?FavoriteCheckedImg:FavoriteCheckImg" alt="">
        {{ FavoriteFlag?'已订阅':'订阅' }}
      </div>
    </el-row>
  </div>
</template>

<script>
// import Qs from "qs";
import PicZoom from "vue-piczoom";
import { 
  ProductIsFavorite,
  ProductFavSubmit
} from "api/member.js";
export default {
  name: "ShopGalss",
  data() {
    return {
      imgurl:'',
      imgList: [],
      imgIndex:0,
      FavoriteFlag:false,
      FavoriteCheckImg:require("@/assets/FavoriteCheck.png"),
      FavoriteCheckedImg:require("@/assets/FavoriteChecked.png"),
    };
  },
  props: {
    goodsinfo: {
      type: Object,
    },
  },
  components: {
    PicZoom,
  },
  methods: {
    init(){
      ProductIsFavorite(this.goodsinfo.id).then(res=>{
        console.log('aaaaa',res.data)
        if(res.data.code==400){
          this.FavoriteFlag=false
        }else{
          if(res.data.data==1){
            this.FavoriteFlag=true
          }else{
            this.FavoriteFlag=false
          }
        }
      })
    },
    FavoriteFun(){
      console.log(this.goodsinfo)
       this.FavoriteFlag=!this.FavoriteFlag
     
      //  let memberStoreId=Qs.parse(sessionStorage.getItem("memberData")).memberStoreId
      //  let memberId=Qs.parse(sessionStorage.getItem("memberData")).memberId
      //  console.log(memberStoreId,memberId)

       let isFavorite=this.FavoriteFlag?'1':'0'
       console.log('isFavorite',isFavorite)
       let datas={
        isFavorite: isFavorite,   //是否收藏（0->否 1->是）
        productId: this.goodsinfo.id,
       }
      //  ProductFavSave(datas).then(res=>{
      //    console.log(res)
      //  })
      ProductFavSubmit(datas).then(res=>{
         this.$message({
                message:res.msg,
                type: 'success'
            });
       })

     

    },
    left:function(){
            if(this.imgIndex==0){
                return false
            }
            this.imgIndex--;
            this.imgurl = this.imgList[this.imgIndex];
             if(this.imgIndex>4){
              let left=parseInt(document.getElementById('imgBox').style.left)+78
              document.getElementById('imgBox').style.left=left+'px'
            }else{
               document.getElementById('imgBox').style.left=21+'px'
            }
    },
    right:function(){
            if(this.imgIndex>=this.imgList.length-1){
                return false
            }
            this.imgIndex++;
            this.imgurl = this.imgList[this.imgIndex];
            if(this.imgIndex>4){
              let left=-(Number(this.imgIndex)-4)*78+21
              document.getElementById('imgBox').style.left=left+'px'
            }
    },
    changeImage(index) {
      this.imgIndex=index;
      this.imgurl = this.imgList[index];
      
    },
  },
  created() {
    if(this.goodsinfo.pic!=""){
       this.imgurl=this.goodsinfo.albumPics.split(",")[0]
       this.imgList=this.goodsinfo.albumPics.split(",")
    }
    this.init()
  },
};
</script>
<style lang="less" scoped>
@import "~style/index.less";
.FavBox{
     height:30px;
     line-height:30px;
     text-indent:40px;
     position: relative;
     margin:20px 0 15px 0;
     cursor: pointer;
     .FavIcon{
       position:absolute;
       width:24px;
       height:24px;
       left: 0;
       top:3px;
       display:block;
     }
  }
.showimg {
  width: 430px;
  height: 430px;
  border: 1px solid #ccc;

  .magnifier-box {
    position: relative;
    
    .mouse-cover {
      position: fixed !important;
      top: 200px !important;
      left: 200px !important;
      right: 40px;
      background: #eee !important;
    }
  }
  .mouse-cover {
    background: red !important;
  }
}
.wraper {
  padding: 0px 21px 10px;
  width: 432px;
  height: 100px;
  position: relative;
  overflow: hidden;
  display: flex;
  box-sizing: border-box;
  button{
        top: 9px;
       width:20px!important;
      height: 82px!important;
      float: left;
      border: 1px solid #f6f6f6;
      margin: 0 3px 14px 3px;
      border: none;
      padding: 0;
      background: #fff;
      outline: none;
      z-index:99;
      cursor:pointer;
     &.active{
       pointer-events:none;
     }
    }
    button:first-child {
      left:-3px;
      .galss();
      i{
        font-size: 24px;
        font-weight: bold;
      }
    }
    button:last-child {
      margin-right: 0px;
      right: 0px;
      .galss();
       i{
        font-size: 24px;
        font-weight: bold;
      }
    }
  ul {
    position:absolute;
    left:21px;
    width:auto;
    height: 100%;
    padding-top: 14px;
        z-index: 2;
    li {
      width: 70px;
      height: 70px;
      float: left;
      border: 1px solid #f6f6f6;
      margin: 0 3px 14px 3px;
      &.imgActive {
        transform: translateY(-1px);  
        box-shadow: 0px 0px 3px rgb(255, 51, 51);
      }

      img {
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
    
  }
}
</style>
